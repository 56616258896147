<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <!-- name -->
            <b-col cols="6">
              <LocaleTabs
                :errors="formErrors"
                name="name"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`name.${k}`"
                    :name="`${i} Name`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Name`">
                      <b-form-input
                        v-model="form.name[k]"
                        :placeholder="`${i} Name`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col cols="6">
              <LocaleTabs
                :errors="formErrors"
                name="job_title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`job_title.${k}`"
                    :name="`${i} Job Title`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Job Title`">
                      <b-form-input
                        v-model="form.job_title[k]"
                        :placeholder="`${i} Job Title`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="content"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`content.${k}`"
                    :name="`${i} Content`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Content`">
                      <b-form-textarea
                        v-model="form.content[k]"
                        :placeholder="`${i} Content`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col cols="12" class="my-2">
              <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">Avatar</legend>
              <ValidationProvider
                  v-slot="{ errors }"
                  vid="avatar"
                  name="Avatar"
              >
                <MediaLibraryAttachment
                    ref="mediaComponent"
                    :initial-value="media"
                    :max-items="1"
                    name="avatar"
                    :headers="headers"
                    routePrefix="media-library"
                    :uploadDomain="baseURL"
                    @change="getFiles($event)"
                    :validation-rules="{ accept: ['image/webp'], maxSizeInKB: 2048 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <b-col cols="6">
              <validation-provider
                  v-slot="{ errors }"
                  vid="rating"
                  name="Rating"
                  rules="required"
              >
                <b-form-group
                    label="Rating"
                >
                  <v-select
                      v-model="form.rating"
                      label="value"
                      :options="ratings"
                      :reduce="item => item.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                Status
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { baseURL } from '@/services/url.service'
import MediaLibraryAttachment from '../../components/media-spatie/media-library-pro-vue2-attachment/src/MediaLibraryAttachment.vue'

export default {
  mixins: [formMixin],
  components: {
    MediaLibraryAttachment,
  },
  data() {
    return {
      ratings: [],
      baseURL: baseURL(),
      imageAfterUpload: [],
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      validationErrors: {
        avatar: 'The Avatar field is required',
      },
      media: null,
      avatar_uuid: null,
      form: {
        name: {
          en: null,
          ar: null,
        },
        job_title: {
          en: null,
          ar: null,
        },
        content: {
          en: null,
          ar: null,
        },
        avatar_uuid: null,
        rating: null,
        status: 'active',
      },
      form_data: null,
    }
  },
  watch: {
    avatar_uuid(val) {
      if (val) {
        this.form.avatar_uuid = val
      } else {
        this.form.avatar_uuid = 'delete'
      }
    },
  },
  created() {
    this.loadConstants()
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    loadConstants() {
      this.axios.get(`/${this.resource}/constants`)
          .then(res => {
            const { data } = res.data
            this.ratings = data.ratings
          })
    },
    getObj(el) {
      return { 0: el }
    },
    getFiles(e) {
      const arr = []
      arr.push(e)
      this.imageAfterUpload = arr
      this.form.avatar_uuid = Object.keys(this.imageAfterUpload[0])[0]
      // this.form.avatar = Object.keys(this.imageAfterUpload[0])
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name_value,
            job_title: data.job_title_value,
            content: data.content_value,
            status: data.status,
            rating: data.rating,
            avatar_uuid: data.media?.uuid || null,
          }
          this.media = data.media
          this.avatar_uuid = data.media?.uuid || null

          let arr = []
          const obj = {
            attributes: this.media,
            clientValidationErrors: [],
            client_preview: data.media?.original_url,
            upload: {},
          }
          arr.push(obj)
          this.$refs.mediaComponent.mediaLibrary.changeState(state => {
            state.media = arr
          })
        })
    },
    loadFormData() {
      const $form = _.cloneDeep(this.form)
      this.form_data = $form
      if (this.isEdit) {
        // if (!($form.avatar instanceof File) && $form.avatar !== 'delete') {
        //   delete $form.avatar
        // }
        // eslint-disable-next-line no-unused-vars
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
  },
}
</script>
